import { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import LoginHeader from "../layout/LoginHeader";
import { AuthContext2 } from "../providers/AuthProvider2";
import 'react-toastify/dist/ReactToastify.css';
import { SpinnerContext } from "../providers/SpinnerProvider";
import { SpinnerType, useSpinner } from "../hooks/useSpinner";
import configData from "./../app-config.json";

export default function CmoLogin() {
    const spinner: SpinnerType = useSpinner();
    const authContext = useContext(AuthContext2);
    let navigate = useNavigate();
    let location = useLocation();

    let from = location.state?.from?.pathname || '/cmodashboard';

    async function handleLogin(event: React.FormEvent<HTMLFormElement>) {
        spinner.showLoader();
        event.preventDefault();

        let formData = new FormData(event.currentTarget);
        let userCredentials = {
            accessKey: formData.get("acceskey")?.toString().trim(),
            userType: 1
        }

        let headers: { [key: string]: string } = { "Content-Type": "application/json; charset=utf-8" };
        const body = JSON.stringify(userCredentials);
        const apiUrl = configData.SERVER_URL;
        const url = "cmologin"
        await fetch(apiUrl + url,
            {
                method: "POST",
                headers,
                body,
            }).then(res => {
                if (res.ok) {
                    return res.json();
                }
                else {
                    if (res.status == 401) {
                        toast.error('Unauthorized access');
                        authContext.removeTokenFromSession();
                    } else {
                        toast.error('Some error occured please contact your administrator');
                    }
                }
            }).then(data => {
                if (data) {
                    if (data.isSuccessful) {
                        // authContext.setIsAuthenticated(true);
                        authContext.setCurrentUserType(1);
                        authContext.setToken(data.responseBody.token);
                        // authContext.setAccessKey(userCredentials.accessKey);
                        // authContext.setMethodName(data.responseBody.methodName);
                        navigate("/cmodashboard", { replace: true });
                        // toast.info(data.responseMessage);
                    }
                    else {
                        toast.warn(data.responseMessage);
                    }
                }
            }).catch(res => {
                toast.error('Some error occured while connecting to the server');
            });
        spinner.hideLoader();

    }

    return (
        <div className="login-container">
            {spinner.loader}

            <LoginHeader />

            <div className="login-form-container">
                {/* <Test /> */}
                <form className="login-form" onSubmit={handleLogin}>
                    <section className="cmo-login-form-contents">
                        <header className="login-header">
                            <h1 className="login-header-title">Log in</h1>
                            <h2 className="login-header-subtitle">Please enter your access key</h2>
                        </header>
                        <div className="login-form-fields">
                            <div className="form-group">
                                <label>
                                    <input autoComplete="off"
                                        name="acceskey" type="text"

                                    />
                                    <span>Access Key:{" "}</span>
                                </label>{" "}
                            </div>
                            <button className="login-submit-btn" type="submit">Login</button>

                        </div>

                    </section>

                </form>
            </div>
        </div>



    )
}
// function Test() {
//     let auth = useContext(AuthContext2);
//     console.log("auth in test cmo user",auth);
//     if (!auth.isAuthenticated) {
//         return (<div>Auth not success</div>);
//     }

//     return (<div>Auth success</div>);
// }