import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { AuthContext2 } from "../providers/AuthProvider2";
import { ReactComponent as Logo } from '../contents/logo.svg';

export default function LoginHeader() {
    const logo = require('../contents/logo.svg');
    return (
        <header className="site-header">
            <div className="site-header-image">
                {/* <NavLink to="/"> Home </NavLink> */}
                {/* <img src={logo} /> */}
                <Logo className="logo-image"/>
            </div>

            <nav>
                <LoginLinks />
            </nav>
        </header>
    );
}

function LoginLinks() {
    return (
        <>
            <NavLink className="login-links" to="/cmologin" replace> CMO Login </NavLink>
            <NavLink className="login-links" to="/qubasuserlogin" replace> QuBAS User Login </NavLink>
        </>
    );
}
