import { ReactNode, useState, useContext, useCallback, useEffect } from "react"
import { useDropzone } from 'react-dropzone';
import { FileWithPath } from 'file-selector';
import { AuthContext2 } from "../../providers/AuthProvider2";
import Tabs from "../../widgets/Tabs";
import { toast } from "react-toastify";
import { SpinnerType, useSpinner } from "../../hooks/useSpinner";
import FileUploadHistory from "./FileUploadHistory";
import CmoReports from "./CmoReports";
import configData from "./../../app-config.json";
import { NavLink } from "react-router-dom";
import { ReactComponent as Logo } from '../../contents/logo.svg';

function FileUploadZone({ uploadFiles, forceUpdate }: { uploadFiles: any, forceUpdate: () => void }): JSX.Element {
  const [width, setWidth] = useState("0%")
  const onDrop = useCallback(<T extends FileWithPath>(acceptedFiles: T[]) => {
    uploadFiles(acceptedFiles, setWidth, forceUpdate);

  }, []);

  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps
  } = useDropzone({
    // validator: nameLengthValidator,
    // getFilesFromEvent: event => myCustomFileGetter(event, props),
    onDrop
  });



  return (
    <div>
      <section className="file-upload-section">
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <p>Drag and drop some files here to import, or click to select files</p>
                  <em>(A maximum of 20 files are allowed at once. Total upload size should not exceed 5 MB.)</em>
        </div>
        <aside>

        </aside>

      </section>
      <div>
        <div className="progressbar">
          <div style={{ width: width }}></div>
        </div>
      </div>
    </div>

  );
}

function FileUploadWidget({ forceUpdate }: { forceUpdate: () => void }) {
  // const [filearray, setFiles] = useState([]);
  const authContext = useContext(AuthContext2);
  // function uploadFiles(files: File[], setWidth: (width:string) => void) {
  //   console.log(files);
  //   const formData = new FormData();
  //   for (const file of files) {
  //     console.log("file", file);
  //     formData.append("files", file, file.name);
  //   }

  //   const xhr = new XMLHttpRequest();
  //   xhr.upload.onprogress = event => {
  //     const percentage = +((event.loaded / event.total) * 100).toFixed(2);
  //     console.log(percentage); // Update progress here
  //     setWidth(percentage.toString() + "%")
  //   };
  //   xhr.onreadystatechange = () => {
  //     if (xhr.readyState !== 4) return;
  //     if (xhr.status !== 200) {
  //       console.log('error'); // Handle error here
  //     }
  //     console.log('success'); // Handle success here
  //   };
  //   xhr.open('POST', 'https://localhost:7011/api/cmofileupload', true);
  //   xhr.setRequestHeader("Authorization", "Bearer " + authContext.token);
  //   xhr.send(formData);
  // }

  function uploadFiles(files: File[], setWidth: (width: string) => void, forceUpdate: () => void) {
    let formData = new FormData();
    const maxSizeOfUpload = 5242880;
    let uploadSize = 0;
    // if (files.length > 20) {
    //   toast.warn("Only 20 files can be uploaded at a time");
    //   formData = new FormData();
    //   setWidth("0%");
    //   return;
    // }

    for (const file of files) {
      uploadSize += file.size;
      if (uploadSize > maxSizeOfUpload) {
        toast.warn("A maximum of 5 MB upload is allowed");
        formData = new FormData();
        setWidth("0%");
        return;
      }
      formData.append("files", file, file.name);
    }


    let headers: { [key: string]: string } = {
      "Authorization": "Bearer " + authContext.getTokenFromSession()
    };
    const body = formData;
    const apiUrl = configData.SERVER_URL;
    const url = "cmofileupload"

    setWidth("0%");

    fetch(apiUrl + url,
      {
        method: "POST",
        headers,
        body,
      }).then(res => {
        if (res.ok) {
          return res.json();
        }
        else {
          if (res.status == 401) {
            toast.error('Unauthorized access');
            authContext.removeTokenFromSession();
          } else {
            toast.error('Some error occured please contact your administrator');
          }
        }
      }).then(data => {
        if (data) {
          if (data.isSuccessful) {
            toast.info(data.responseMessage);
            setWidth("100%");
            forceUpdate();
          }
          else {
            toast.warn(data.responseMessage);
            setWidth("0%")

          }
        }
      }).catch(res => {
        toast.error('Some error occured while connecting to the server');
        setWidth("0%")
      });
    // setWidth("0%");

  }

  return (<FileUploadZone uploadFiles={uploadFiles} forceUpdate={forceUpdate} />);
}


interface CMOBasicDetails {
  accessKey: string,
  methodName: string,
}


async function handleGetMethodAndAccessKey(token: string, removeTokenFromSession: () => void) {

  let basicDetails: CMOBasicDetails = {
    accessKey: "",
    methodName: ""
  };

  let headers: { [key: string]: string } = {
    "Content-Type": "application/json; charset=utf-8",
    "Authorization": "Bearer " + token
  };
  const apiUrl = configData.SERVER_URL;
  const url = "cmodetails/basicdetails";

  await fetch(apiUrl + url,
    {
      method: "Get",
      headers,
    }).then(res => {
      if (res.ok) {
        return res.json();
      }
      else {
        if (res.status == 401) {
          toast.error('Unauthorized access');
          removeTokenFromSession();
        } else {
          toast.error('Some error occured please contact your administrator');
        }
      }
    }).then(data => {
      if (data) {
        if (data.isSuccessful) {
          basicDetails = data.responseBody;
          // toast.info(data.responseMessage);
        }
        else {
          toast.warn(data.responseMessage);
        }
      }
    }).catch(res => {
      toast.error('Some error occured while connecting to the server');
    });
  return basicDetails;
}

// function CmoInformation({ hideLoader, showLoader }: { hideLoader: () => void, showLoader: () => void }) {
function CmoInformation() {
  const authContext = useContext(AuthContext2);
  const [basicDetails, setBasicDetails] = useState<CMOBasicDetails>({
    accessKey: "",
    methodName: ""
  });

  let methodName = "";
  let accessKey = "";

  const fetchMethodAndAccessKey = async () => {
    // showLoader();
    var result = await handleGetMethodAndAccessKey(authContext.getTokenFromSession(), authContext.removeTokenFromSession);
    setBasicDetails(result);
    // hideLoader();
  }

  useEffect(() => {
    fetchMethodAndAccessKey();
  }, []);
  return (
    <div className="cmo-details">
      <div className="cmo-method-name">
        <label>
          Method Name:{' '}
        </label>
        <span>
          {basicDetails.methodName}
        </span>
      </div>
      <div className="cmo-access-key">
        <label>
          Access Key: {' '}
        </label>
        <span>
          {basicDetails.accessKey}
        </span>
      </div>
    </div>
  );
}


export default function CmoDashboard() {

  const authContext = useContext(AuthContext2);

  // spinner for filehistory
  const spinner_fh: SpinnerType = useSpinner();
  // spinner for cmoreports
  const spinner_cr: SpinnerType = useSpinner();

  const [trigger, setTrigger] = useState(0)
  // we are doing this to force render the two
  // tables. Ideally we would want a reducer 
  // with all the different types of workflow
  // scenarios in it.
  function forceUpdate() {
    setTrigger((trigger) => trigger + 1);
  }

  const tabsConfig = [
    {
      label: "Upload History",
      content: <FileUploadHistory hideLoader={spinner_fh.hideLoader} showLoader={spinner_fh.showLoader} forceUpdateTrigger={trigger} />,
      icon: ""
    },
    {
      label: "Reports",
      content: <CmoReports hideLoader={spinner_cr.hideLoader} showLoader={spinner_cr.showLoader} forceUpdateTrigger={trigger} />,
      icon: ""
    },
  ];

  // const logo = require('../../contents/logo.svg');

  return (
    <div className="cmo-dashboard">
      {spinner_fh.loader}
      {spinner_cr.loader}
      <div className="cmo-dashboard-header-image">
        {/* <NavLink to="/"> Home </NavLink> */}
        {/* <img className="dashboard-logo-image" src={logo} /> */}
        <Logo className="dashboard-logo-image"/>
        <NavLink className="logout-button" to="/cmologin" onClick={authContext.removeTokenFromSession} replace> Logout </NavLink>
      </div>

      <CmoInformation />
      <div className="file-upload-container">
        <FileUploadWidget forceUpdate={forceUpdate} />
      </div>
      <div>
        <Tabs tabsConfig={tabsConfig} />
      </div>

    </div>
  )
}